import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import i18next from 'i18next'

import logo from '../../img/gDriveLogo.png'

import { setVerifyInputFile, setVerifyDSInputFile, setGoogleAccessToken, setGoogleTokenExpiryTime, setSelectedGoogleFolderId, setFilesForDS, setCertificateInputFile,
         setFilesForEncrypt, setFilesForDecrypt, setFileForPrivateSign, setFileForPrivateSignPng } from '../../actions/localStates'

class GooglePickerWithTokenControl extends Component {
  constructor(props) {
    super(props);
    this.CLIENT_ID = this.props.base.googleDriveClientId;
    this.API_KEY = this.props.base.googleDriveApiKey;
    // this.tokenExpiryTime = 0;
    // this.accessToken;

    this.state = {
      pickerApiLoaded: false,
      filesForDS: this.props.createDSDefaultState.fileList || [],
      filesCount: 0
    };

    this.initializePicker = this.initializePicker.bind(this);
    this.onPickerApiLoad = this.onPickerApiLoad.bind(this);
    this.handleAuthorization = this.handleAuthorization.bind(this);
    this.pickerCallback = this.pickerCallback.bind(this);
    this.fetchFileContent = this.fetchFileContent.bind(this);

    this.initializePickerMultySelect = this.initializePickerMultySelect.bind(this);
    this.pickerMultySelectCallback = this.pickerMultySelectCallback.bind(this);

    this.fetchFileContentMultySelect = this.fetchFileContentMultySelect.bind(this);
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    script.onload = () => window.gapi.load('picker', this.onPickerApiLoad);
    document.body.appendChild(script);
  }

  onPickerApiLoad() {
    this.setState({ pickerApiLoaded: true });
  }

  handleAuthorization() {
    var tokenExpiryTime = this.props.base.googleTokenExpiryTime;
    // var setAccessToken = this.props.base.googleAccessToken;

    // Перевіряємо час дії токену
    if (tokenExpiryTime > Date.now()) {
        if (this.props.multyselect === undefined) {
            this.initializePicker();
            return;
        } else {
            this.initializePickerMultySelect();
            return;
        }
      
    }

    // Ініціалізуємо GIS клієнт та отримуємо новий токен
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/drive.file',
      callback: (response) => {
        if (response && response.access_token) {
            this.props.actions.setGoogleAccessToken(response.access_token)
            this.props.actions.setGoogleTokenExpiryTime(Date.now() + 3600 * 1000)
            if (this.props.multyselect === undefined) {
                this.initializePicker();
            } else {
                this.initializePickerMultySelect();
            }
        } else {
          console.error("Authorization error:", response);
        }
      },
    });
    this.tokenClient.requestAccessToken();
  }

  initializePickerMultySelect = () => {
    const { pickerApiLoaded } = this.state;
    var accessToken = this.props.base.googleAccessToken;
    if (pickerApiLoaded && accessToken) {
        // const view = new window.google.picker.DocsView()
        //   .setIncludeFolders(true)
        //   .setSelectFolderEnabled(false); // Вимкнути вибір папок, залишити тільки файли

        const picker = new window.google.picker.PickerBuilder()
            .addView(new window.google.picker.DocsView()
                .setIncludeFolders(true)
                .setSelectFolderEnabled(false)
                .setOwnedByMe(true)
            )
            .addView(new window.google.picker.DocsView()
            .setIncludeFolders(true)
            .setSelectFolderEnabled(false)
            .setOwnedByMe(false)
            )
          .setOAuthToken(accessToken)
          .setDeveloperKey(this.API_KEY)
          .setLocale('uk')
          .setAppId(this.props.base.googleDriveAppId)
          .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED) // Дозволити множинний вибір
          .setCallback(this.pickerMultySelectCallback)
          .build();

        picker.setVisible(true);
    }
  };

  pickerMultySelectCallback = async (data) => {
    if (data.action === window.google.picker.Action.PICKED) {
          const selectedFiles = data.docs;
          const files = selectedFiles.map(file => file);
          this.setState({filesCount: files.length - 1})


          // Завантаження вмісту кожного файлу в пам'ять
          const contents = await Promise.all(files.map(this.fetchFileContentMultySelect, files[files.id]));

        } else if (data.action === window.google.picker.Action.CANCEL) {
          console.log("Picker was cancelled.");
    }
  };

  initializePicker() {
    const { pickerApiLoaded } = this.state;
    var accessToken = this.props.base.googleAccessToken;
    if (pickerApiLoaded && accessToken) {

        if (this.props.createDSOptionsReducer.isPrivateSignature) {
            const picker = new window.google.picker.PickerBuilder()
            .setOAuthToken(accessToken)
            .addView(new window.google.picker.DocsView()
                .setIncludeFolders(true)
                .setSelectFolderEnabled(true)
                .setOwnedByMe(true)
                .setMimeTypes(this.props.createDSDefaultState.pngChecked && this.props.caller !== "fileForPrivateSing" ? "image/png" : "application/pdf")
            )
            .addView(window.google.picker.ViewId.FOLDERS)
            .addView(new window.google.picker.DocsView()
                .setIncludeFolders(true)
                .setSelectFolderEnabled(true)
                .setOwnedByMe(false)
                .setMimeTypes(this.props.createDSDefaultState.pngChecked && this.props.caller !== "fileForPrivateSing" ? "image/png" : "application/pdf")
            )
            .setDeveloperKey(this.API_KEY)
            .setLocale('uk')
            .setAppId(this.props.base.googleDriveAppId)
            .setCallback(this.pickerCallback)
            .build();
          picker.setVisible(true);
        } else {
            const picker = new window.google.picker.PickerBuilder()
            .setOAuthToken(accessToken)
            .addView(new window.google.picker.DocsView()
                .setIncludeFolders(true)
                .setSelectFolderEnabled(true)
                .setOwnedByMe(true)
            )
            .addView(window.google.picker.ViewId.FOLDERS)
            .addView(new window.google.picker.DocsView()
                .setIncludeFolders(true)
                .setSelectFolderEnabled(true)
                .setOwnedByMe(false)
            )
            .setDeveloperKey(this.API_KEY)
            .setLocale('uk')
            .setAppId(this.props.base.googleDriveAppId)
            .setCallback(this.pickerCallback)
            .build();
          picker.setVisible(true);
        }
        
    } else {
      console.log("Picker or access token not ready");
    }
  }

    pickerCallback(data) {
        if (data.action === window.google.picker.Action.PICKED) {
            const file = data.docs[0];
            console.log('Selected file:', file);
            const folder = data.docs[0];
            console.log('Selected folder:', folder);
            this.props.actions.setSelectedGoogleFolderId(folder.parentId);
            this.setState({"fileName" : file.name})
            this.setState({"mimeType" : file.mimeType})
            this.fetchFileContent(file.id, file.name);
        }
    }

    fetchFileContentMultySelect = async (files, index) => {
        var _this = this, arr = [];

        switch (this.props.caller) {
          case "filesForDS": 
            arr = this.props.createDSDefaultState.fileList || []
            break;

          case "filesForEncrypt":
            arr = this.props.encryptDefaultState.fileList || []
            break;

          case "filesForDecrypt":
            arr = this.props.defaultDecrState.fileList || []
            break;
        }

        const url = `https://www.googleapis.com/drive/v3/files/${files.id}?alt=media`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.props.base.googleAccessToken}`, // Використовуємо токен авторизації
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // const contentType = response.headers.get('Content-Type') || 'application/octet-stream'; // Отримуємо MIME-тип
            return response.blob().then(blob => {
                const file = new File([blob], files.name, { type: files.mimeType }); // Створюємо File з Blob
                // console.log('File object:', file);
                arr.push(file)

                if (index === this.state.filesCount) {
                    switch (this.props.caller) {
                      case "filesForDS": 
                        this.props.actions.setFilesForDS(arr)
                        this.props.setFilesCountState()
                        this.setState({filesForDS: []})
                        break;

                      case "filesForEncrypt":
                        this.props.actions.setFilesForEncrypt(arr)
                        this.props.setFilesCountState()
                        this.setState({filesForDS: []})
                        break;

                      case "filesForDecrypt":
                        this.props.actions.setFilesForDecrypt(arr)
                        this.props.setFilesCountState()
                        this.setState({filesForDS: []})
                        break;
                    }
                    
                }
                // switch (this.props.caller) {
                //   case "filesForDS": 
                //     return file;
                //     // this.props.actions.setFilesForDS(files)
                //     break;

                //   case "fileInput":
                //     this.props.actions.setVerifyInputFile(file)
                //     break;

                //   case "fileWithDS":
                //     this.props.actions.setVerifyDSInputFile(file)
                //     break;
                // }
                // this.setState({ file, fileId, fileName, mimeType: _this.state.mimeType }); // Зберігаємо у стані
                console.log(this.state.filesForDS)
                return file;
            });
        })
        .catch(error => {
            console.error('Error fetching file content as Blob:', error);
        });
    }

    fetchFileContent(fileId, fileName) {
        var _this = this;
        const url = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.props.base.googleAccessToken}`, // Використовуємо токен авторизації
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // const contentType = response.headers.get('Content-Type') || 'application/octet-stream'; // Отримуємо MIME-тип
            return response.blob().then(blob => {
                const file = new File([blob], fileName, { type: _this.state.mimeType }); // Створюємо File з Blob
                console.log('File object:', file);
                switch (this.props.caller) {
                  case "filesForDS": 
                    this.props.actions.setFilesForDS(files)
                    break;

                  case "fileInput":
                    this.props.actions.setVerifyInputFile(file)
                    break;

                  case "fileForPrivateSing":
                    this.props.actions.setFileForPrivateSign(file)
                    break;

                  case "fileForPrivateSingPng":
                    this.props.actions.setFileForPrivateSignPng(file)
                    break;

                  case "fileWithDS":
                    this.props.actions.setVerifyDSInputFile(file)
                    break;

                  case "crtFile":
                    this.props.actions.setCertificateInputFile(file)
                    break;

                  case "filesForDecrypt":
                    this.props.actions.setFilesForDecrypt(files)
                    break;
                }
                this.setState({ file, fileId, fileName, mimeType: _this.state.mimeType }); // Зберігаємо у стані
            });
        })
        .catch(error => {
            console.error('Error fetching file content as Blob:', error);
        });
    }

  render() {
    var activeClass = ""
    var activeClassDiv = "col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding"
    var textOnButton = ""

        switch (this.props.caller) {
          case "filesForDS": 
            activeClass = "btn btn-default btn-block"
            activeClassDiv = "col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding"
            textOnButton = "getFilesFromGoogleDrive"
            break;

          case "fileInput":
            activeClass = "btn btn-default gDriveButton"
            textOnButton = "openGDrive"
            break;

          case "fileForPrivateSing":
            activeClass = "btn btn-default gDriveButton"
            textOnButton = "openGDrive"
            break;

          case "fileForPrivateSingPng":
            activeClass = "btn btn-default gDriveButton"
            textOnButton = "openGDrive"
            break;

          case "fileWithDS":
            activeClass = "btn btn-default gDriveButton"
            textOnButton = "openGDrive"
            break;

          case "crtFile":
            activeClass = "btn btn-default gDriveButton"
            textOnButton = "openGDrive"
            break;

          case "filesForEncrypt":
            activeClass = "btn btn-default btn-block"
            activeClassDiv = "col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding"
            textOnButton = "getFilesFromGoogleDrive"
            break;

          case "filesForDecrypt":
            activeClass = "btn btn-default btn-block"
            activeClassDiv = "col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding"
            textOnButton = "getFilesFromGoogleDrive"
            break;
        }
    return (
      <div className={activeClassDiv}>
        <button onClick={this.handleAuthorization} className={activeClass}><img className="img" src={logo} width="24" height="24" alt="Logo" />{i18next.t(textOnButton)}</button>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        base: state.base,
        language: state.localesReducer.language,
        localesReducer: state.localesReducer,
        createDSDefaultState: state.createDSInputReducer,
        encryptDefaultState: state.encryptFilesReducer,
        defaultDecrState: state.decryptReducer,
        createDSOptionsReducer: state.createDSOptionsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
        setVerifyInputFile,
        setVerifyDSInputFile,
        setGoogleAccessToken,
        setGoogleTokenExpiryTime,
        setSelectedGoogleFolderId,
        setFilesForDS, setCertificateInputFile,
        setFilesForEncrypt, setFilesForDecrypt,
        setFileForPrivateSign, setFileForPrivateSignPng
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GooglePickerWithTokenControl);